import Layout from "@/components/layout";
import { ENVIRONMENT } from "@/utils/environment";
import Head from "next/head";
import { BythenNavbarProvider } from "./landing/components/BythenNavbar/context";
import BythenNavbar from "./landing/components/BythenNavbar";
import SectionUseCase from "./landing/components/SectionUseCase";
import SectionProducts from "./landing/components/SectionProducts";
import HeroLanding from "./landing/components/HeroLanding";
import SectionIntroduce from "./landing/components/SectionIntroduce";
import SectionTeam from "./landing/components/SectionTeam";
import SectionBackers from "./landing/components/SectionBackers";
import SectionBottom from "./landing/components/SectionBottom";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";

const BythenCollection = dynamic(
  () => import("./landing/components/BythenCollection"),
  {
    ssr: false,
  }
);

const SectionLoadLater = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const toggleShow = () => {
      setIsShow((curr) => (!curr ? true : curr));
    };

    if (!isShow) {
      window.addEventListener("scroll", toggleShow);

      return () => {
        window.removeEventListener("scroll", toggleShow);
      };
    }
  }, [isShow]);

  return (
    <>
      {isShow && (
        <>
          <SectionTeam />
          <SectionBackers />
          <SectionBottom />
        </>
      )}
    </>
  );
};

export default function LandingPage() {
  return (
    <>
      <Head>
        <title>bythen</title>
        <meta
          name="description"
          content="Creator of Bytes, advanced PFP that let you own your unique character IP, transforming your pseudonym identity into a living character with AI technology."
        />
        <meta name="robots" content="index,follow" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:url" content={`${ENVIRONMENT.BASE_URL}/quest`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image:alt"
          content="bythen - Collect as much energy possible to earn the greatest rewards."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="bythen" />

        <meta property="og:title" content="bythen" />
        <meta itemProp="name" content="bythen" />

        <meta
          property="og:description"
          content="Creator of Bytes, advanced PFP that let you own your unique character IP, transforming your pseudonym identity into a living character with AI technology."
        />
        <meta
          itemProp="description"
          content="Creator of Bytes, advanced PFP that let you own your unique character IP, transforming your pseudonym identity into a living character with AI technology."
        />

        <meta
          property="og:image"
          content={`${ENVIRONMENT.ASSETS_URL}/landing/og-image-new.png`}
        />
        <meta
          itemProp="image"
          content={`${ENVIRONMENT.ASSETS_URL}/landing/og-image-new.png`}
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:image:type" content="image/png" />
      </Head>
      <Layout>
        <BythenNavbarProvider>
          <BythenNavbar />
          <HeroLanding />
          <SectionIntroduce />
          <SectionUseCase />
          <BythenCollection />
          <SectionProducts />
          <SectionLoadLater />
        </BythenNavbarProvider>
      </Layout>
    </>
  );
}
