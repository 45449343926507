import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import {
  motion,
  useScroll,
  useMotionValueEvent,
  useAnimate,
  useInView,
} from "framer-motion";
import { Play } from "@phosphor-icons/react";
import { ENVIRONMENT } from "@/utils/environment";
import Button from "@/components/Button";
import { SectionContentContainer } from "./components/SectionContentContainer";
import { SectionUseCaseHeader } from "./components/SectionUseCaseHeader";
import useSectionWithNavbar from "../../hooks/useSectionWithNavbar";
import TextRotateFromBottom from "../Animation/TextRotateFromBottom";

const SectionUseCaseA = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentGridRef = useRef(null);
  const whiteFilterRef = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }, { delay: 0.7 }],
        [contentGridRef.current, { opacity: [0, 1] }, { duration: 0.7 }],
        [
          whiteFilterRef.current,
          { height: ["100%", "1%"] },
          { duration: 0.7, at: "<" },
        ],
      ]);
    }
  }, [isAnimate]);

  const renderImage = (src) => (
    <>
      <div className="relative hidden lg:block">
        <Image
          fill
          unoptimized
          src={src}
          className="w-full h-full object-contain"
          alt="img-section-a-1"
        />
      </div>
      <div className="relative block lg:hidden">
        <Image
          width={552}
          height={468}
          unoptimized
          src={src}
          className="w-full h-full object-contain"
          alt="img-section-a-1"
        />
      </div>
    </>
  );

  const renderDesc = (title, desc) => (
    <div>
      <p
        className={`
          text-[18px] min-[1366px]:text-[20px]
          leading-[24px] min-[1366px]:leading-[28px]
          font-medium
          mb-[8px] min-[1366px]:mb-[12px]

          [@media(max-height:768px)]:text-[18px]
          [@media(max-height:768px)]:leading-[24px]
        `}
      >
        {title}
      </p>
      <p
        className={`
          text-[14px] min-[1366px]:text-[16px]
          leading-[20px] min-[1366px]:leading-[24px]
          tracking-[0.02em]

          [@media(max-height:768px)]:text-[14px]
          [@media(max-height:768px)]:leading-[20px]
        `}
      >
        {desc}
      </p>
    </div>
  );

  return (
    <>
      <SectionUseCaseHeader
        className="mb-[24px] lg:mb-[16px] px-4 pt-10 sm:px-[min(5%,64px)] lg:p-0"
        title={
          <span ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              PERSONALIZED
            </TextRotateFromBottom>
            <br />
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              AI AGENT
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <div ref={descRef}>
            <p>
              Bytes is your ultimate customizable AI agent, tailored to meet
              your daily needs and enhance your productivity. Unlocking the full
              potential and value of your PFP.
            </p>
            <Button
              type="black"
              classNames="py-[14px] px-[16px] md:px-[24px] mt-5"
              onClickButton={() =>
                window.open(`${ENVIRONMENT.BASE_URL}/demo`, "_blank")
              }
            >
              <span className="font-founders text-[16px] leading-[20px] md:text-[20px] md:leading-[20px] tracking-wide font-semibold mr-1">
                TRY LIVE DEMO
              </span>
              <div className="w-[20px] aspect-square flex justify-center items-center">
                <Play width={14} height={14} weight="fill" />
              </div>
            </Button>
          </div>
        }
      />

      <div className="relative h-full w-full [@media(max-height:768px)_and_(min-width:1366px)]:-mb-4">
        <div
          ref={contentGridRef}
          className="relative h-full w-full grid grid-cols-[repeat(3,minmax(238px,1fr))] grid-rows-[minmax(0,1fr)_0fr] md:gap-x-[24px] gap-[16px] overflow-auto px-4 pb-10 sm:px-[min(5%,64px)] lg:p-0"
        >
          {renderImage(`${ENVIRONMENT.ASSETS_URL}/landing/section_3_a_1.png`)}
          {renderImage(`${ENVIRONMENT.ASSETS_URL}/landing/section_3_a_2.png`)}
          {renderImage(`${ENVIRONMENT.ASSETS_URL}/landing/section_3_a_3.webp`)}

          {renderDesc(
            `Smart Companion`,
            `Customize your Bytes personality to your imagination. Its emotionally intelligent AI and memory retention enable lifelike responses to your interactions.`
          )}
          {renderDesc(
            `Unleash your Byte`,
            `Bytes can be your alter ego or digital twin, accessible worldwide. Set a distinct personality to achieve personal objectives, like generating business leads.`
          )}
          {renderDesc(
            `Do More With Bytes`,
            `Bytes can perform a wide range of tasks like reserving restaurants, ordering food, creating trip itineraries, booking tickets and hotels, and more!`
          )}
        </div>

        <div
          ref={whiteFilterRef}
          className="absolute bottom-0 left-0 h-full w-full bg-white pointer-events-none select-none"
        />
      </div>
    </>
  );
};

const SectionUseCaseB = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }],
        [contentRef1.current, { opacity: [0, 1] }, { duration: 0.7 }],
        [contentRef2.current, { opacity: [0, 1] }, { duration: 0.7 }],
      ]);
    }
  }, [isAnimate]);

  return (
    <>
      <SectionUseCaseHeader
        className="items-center mb-[24px] md:mb-[44px]"
        title={
          <span ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              AUGMENTED
            </TextRotateFromBottom>
            <br />
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              DIGITAL IDENTITY
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <p ref={descRef}>
            Bytes seamlessly maintains a consistent digital identity across
            various platforms, including video conferencing, VR, streaming, and
            gaming skins, ensuring a unified and recognizable presence
            everywhere.
          </p>
        }
      />

      <div ref={contentRef1} className="relative h-full w-full lg:flex hidden">
        <Image
          fill
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/section_3_b_1.webp`}
          className="w-full h-full object-contain object-top scale-[1.05] xl:scale-[1.15] [@media(max-height:700px)_and_(min-width:1366px)]:scale-[1.3] xl:transform xl:-translate-x-[1.4%]"
          alt="hero-image"
        />
      </div>

      <div ref={contentRef2} className="relative block lg:hidden">
        <Image
          width={1602}
          height={861}
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/section_3_b_1.webp`}
          className="w-full h-full max-h-[50vh] object-contain object-top scale-[1.025]"
          alt="hero-image"
        />
      </div>
    </>
  );
};

const SectionUseCaseC = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }],
        [
          contentRef1.current,
          { opacity: [0, 1] },
          { duration: 0.7, at: "-0.3" },
        ],
        [
          contentRef2.current,
          { opacity: [0, 1] },
          { duration: 0.7, at: "-0.3" },
        ],
      ]);
    }
  }, [isAnimate]);

  return (
    <>
      <SectionUseCaseHeader
        className="items-center mb-[24px] md:mb-[44px]"
        title={
          <span ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              CONTENT
            </TextRotateFromBottom>
            <br />
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              GENERATION TOOL
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <p ref={descRef}>
            Create stunning, high-quality video or image with our advanced AI
            video generator and content editor tools. Elevate your storytelling
            effortlessly, then distribute across social media platforms to
            expand your reach.
          </p>
        }
      />

      <div className="relative h-full w-full flex justify-center items-start">
        <div className="h-full w-full md:w-auto block md:grid md:grid-rows-1 md:grid-cols-[minmax(0,1fr)_0.5fr] md:gap-[48px] justify-center items-center">
          <div
            ref={contentRef1}
            className="relative w-full h-full aspect-[738/472]"
          >
            <Image
              fill
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_1.webp`}
              className="w-full h-full object-contain"
              alt="illust-section-c-1"
            />
          </div>
          <div
            ref={contentRef2}
            className="w-full md:w-[366px] flex flex-col mb-[6px] mt-[28px] md:mb-0 md:mt-0"
          >
            {[
              {
                icon: `${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_ico_list_1.png`,
                label: "Add or Edit Text to your content",
              },
              {
                icon: `${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_ico_list_2.png`,
                label: "Cinematic Filters and Various templates",
              },
              {
                icon: `${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_ico_list_3.png`,
                label: (
                  <>
                    Upload your own Asset (Audio
                    <span className="hidden md:block"> </span>/Video/Image)
                  </>
                ),
              },
              {
                icon: `${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_ico_list_5.png`,
                label: "Advance Voice Features (Voice cloning, AI voice over)",
              },
              {
                icon: `${ENVIRONMENT.ASSETS_URL}/landing/section_3_c_ico_list_4.png`,
                label:
                  "Gen - AI Capabilities (Image Generator, Multi Format Content Generator)",
              },
            ].map((d, idx, list) => (
              <div
                key={idx}
                className={`flex items-center gap-[20px] ${
                  idx > 0 ? "border-t border-t-byteBlack-1000" : ""
                } ${
                  idx === 0
                    ? "pb-[16px] [@media(max-height:768px)]:pb-[12px] [@media(min-height:768px)_and_(max-height:820px)]:pb-[14px] min-[1366px]:pb-[18px]"
                    : idx === list.length - 1
                    ? "pt-[16px] [@media(max-height:768px)]:pt-[12px] [@media(min-height:768px)_and_(max-height:820px)]:pt-[14px] min-[1366px]:pt-[18px]"
                    : "py-[16px] [@media(max-height:768px)]:py-[12px] [@media(min-height:768px)_and_(max-height:820px)]:py-[14px] min-[1366px]:py-[18px]"
                }`}
              >
                <div
                  className={`
                    relative shrink-0 
                    w-[36px] h-[36px] xl:w-[40px] xl:h-[40px] min-[1366px]:w-[44px] min-[1366px]:h-[44px] 
                    [@media(max-height:768px)]:w-[32px] [@media(max-height:768px)]:h-[32px] [@media(min-height:768px)_and_(max-height:820px)]:w-[36px] [@media(min-height:768px)_and_(max-height:820px)]:h-[36px]
                  `}
                >
                  <Image
                    fill
                    unoptimized
                    src={d.icon}
                    className="w-full h-full"
                    alt="illust-ico-section-c-1"
                  />
                </div>
                <p
                  className={`font-medium
                  text-[16px] leading-[20px]
                  xl:text-[18px] xl:leading-[24px]
                  min-[1366px]:text-[20px] min-[1366px]:leading-[28px]
                  [@media(max-height:768px)]:text-[16px] [@media(max-height:768px)]:leading-[20px]
                  `}
                >
                  {d.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default function SectionUseCase() {
  const sectionUseCaseRef = useSectionWithNavbar({ isBlack: true });
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const [showContainer, setShowContainer] = useState(false);

  const { scrollYProgress } = useScroll({
    target: sectionUseCaseRef,
    offset: ["start start", "end end"],
  });

  const { scrollYProgress: scrollYProgressFade } = useScroll({
    target: sectionUseCaseRef,
    offset: ["start 40vh", "end end"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest < 1) {
      setCurrentActiveIndex(Math.floor(latest / (1 / 3)));
    }
  });

  useMotionValueEvent(scrollYProgressFade, "change", (latest) => {
    if (!showContainer) {
      setShowContainer(latest > 0);
    }
  });

  const scrollToSection = (currIndex) => {
    const BLOCK_POS = {
      0: "start",
      1: "center",
      2: "end",
    };

    if (sectionUseCaseRef.current) {
      sectionUseCaseRef.current.scrollIntoView({
        behavior: "smooth",
        block: BLOCK_POS[currIndex],
        inline: "center",
      });
    }
  };

  return (
    <div
      ref={sectionUseCaseRef}
      className="relative h-full lg:h-[calc(100vh*3)] bg-white"
    >
      <div className="relative lg:sticky lg:top-0 lg:overflow-hidden">
        <motion.div
          className="h-full lg:h-screen w-full flex flex-col"
          initial="initial"
          animate={showContainer ? "animate" : "initial"}
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            },
          }}
        >
          {/* <BythenLogoNav isBlack /> */}
          <div className="hidden lg:block h-[90px]" />
          <div className="shrink-0 h-[56px] w-full border-t border-t-byteBlack-1000 border-b border-b-byteBlack-1000">
            <p className="flex justify-center items-center w-[158px] lg:w-[254px] h-full border-r border-r-byteBlack-1000 font-mono font-medium text-[14px] leading-[16px] lg:text-[16px] lg:leading-[20px]">
              BYTE USE CASE
            </p>
          </div>

          <div className="relative h-full w-full lg:flex hidden">
            <SectionContentContainer
              title="Content Generation Tool"
              number={3}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseC isAnimate={currentActiveIndex === 2} />
            </SectionContentContainer>
            <SectionContentContainer
              title="Augmented Digital Identity"
              number={2}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseB isAnimate={currentActiveIndex === 1} />
            </SectionContentContainer>
            <SectionContentContainer
              title="Personalized AI Agent"
              number={1}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseA
                isAnimate={showContainer && currentActiveIndex === 0}
              />
            </SectionContentContainer>
          </div>

          <div className="relative h-full w-full block lg:hidden overflow-hidden">
            <div className="border-b border-b-byteBlack-1000">
              <SectionUseCaseA />
            </div>
            <div className="border-b border-b-byteBlack-1000 p-4 pt-10 sm:py-10 sm:px-[min(5%,64px)]">
              <SectionUseCaseB />
            </div>
            <div className="border-b border-b-byteBlack-1000 p-4 pt-10 sm:py-10 sm:px-[min(5%,64px)]">
              <SectionUseCaseC />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
