import ArrowRightIcon from "@/components/Icon/ArrowRightIcon";
import { ENVIRONMENT } from "@/utils/environment";
import { Play } from "@phosphor-icons/react";
import Image from "next/image";
import { motion } from "framer-motion";
import { useState } from "react";
import TextRotateFromBottom from "./Animation/TextRotateFromBottom";
import HeaderSpace from "@/components/HeaderSpace";
import useSectionWithNavbar from "../hooks/useSectionWithNavbar";
import Button from "@/components/Button";

export default function HeroLanding() {
  const [showDetail, setShowDetail] = useState(false);
  const heroLandingRef = useSectionWithNavbar({ isBlack: true });

  const onAnimationComplete = () => {
    setShowDetail(true);
  };

  function onOpenWhitepaper() {
    window.open("https://bythen-ai.gitbook.io/bythen-ai", "_blank");
  }

  function onOpenDemo() {
    window.open(`${ENVIRONMENT.BASE_URL}/demo`, "_blank");
  }
  return (
    <div className="overflow-x-hidden" ref={heroLandingRef}>
      <HeaderSpace />
      <div
        style={{
          "--title-size": "clamp(36px,calc(11vw),94px)",
          "--title-size-desktop": "clamp(99.75px,calc(10.5vw),160px)",
        }}
        className="py-[16px] px-[16px] md:p-[calc(var(--title-size)*0.5)] md:pr-[calc(var(--title-size)*0.2)] max-w-[1920px] mx-auto break-words"
      >
        <motion.h1
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
          }}
          className="font-founders font-semibold tracking-normal md:tracking-[0.02em] text-[length:--title-size] leading-[calc(var(--title-size)*(44/56))] md:text-[length:--title-size-desktop] md:leading-[calc(var(--title-size-desktop)*(120/160))]"
        >
          <TextRotateFromBottom
            classNames="pb-[30px] -mt-[20px] -mb-[30px]"
            index={0}
          >
            BE
            <span className="relative">
              <span className="invisible tracking-[0.1em]">A</span>
              <span className="absolute top-[20%] h-[64%] w-[calc(100%+20px)] left-[-10px]">
                <Image
                  unoptimized
                  fill
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="absolute h-full w-full left-0 top-0 right-0 bottom-0 text-transparent"
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-1.svg`}
                />
              </span>
            </span>
            PART
            <span className="relative ml-2">
              <span className="invisible tracking-[0.3em]">O</span>
              <span className="absolute top-[20%] h-[66%] w-full left-0">
                <Image
                  unoptimized
                  fill
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="h-full text-transparent"
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-2.png`}
                />
              </span>
            </span>
            F THE&nbsp;
          </TextRotateFromBottom>
          <TextRotateFromBottom
            classNames="p-[50px_40px_50px_0] m-[-50px_-40px_-50px_0]"
            index={1}
          >
            <span className="relative mr-[6px] md:mr-2">
              <span className="invisible tracking-[0em] md:-tracking-[5px]">
                DIGITAL
              </span>
              <span className="absolute top-[26%] md:top-[29%] left-0">
                <Image
                  unoptimized
                  fill
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="!relative"
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-digital.svg`}
                />
              </span>
            </span>
            <span>
              IDENTITY&nbsp;
              <span className="relative">
                <span className="invisible tracking-[0.03em]">O</span>
                <span className="absolute top-[7%] h-[75%] w-auto -left-[25%]">
                  <Image
                    unoptimized
                    alt=""
                    width={120}
                    height={120}
                    className="relative w-full h-auto"
                    src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-3.png`}
                  />
                </span>
              </span>
            </span>
          </TextRotateFromBottom>
          <TextRotateFromBottom classNames="py-[20px] -my-[20px]" index={2}>
            <span>
              R
              <span className="relative -mx-1 md:mx-[-7px]">
                <span className="invisible tracking-[0.24em]">E</span>
                <span className="absolute top-[23%] h-[60%] aspect-square left-0">
                  <Image
                    unoptimized
                    fill
                    alt=""
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="absolute h-full w-full left-0 top-0 right-0 bottom-0 text-transparent"
                    src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-4.png`}
                  />
                </span>
              </span>
              VOLUTION
            </span>{" "}
            WITH
          </TextRotateFromBottom>
          <TextRotateFromBottom index={3}>
            BYTHEN&apos;S
            <span className="relative ml-[5px] -mr-[5]">
              <span className="invisible tracking-[0.4em]">I</span>
              <span className="absolute top-[20%] h-[60%] w-full left-[-5%]">
                <Image
                  unoptimized
                  fill
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="absolute h-full w-full left-0 top-0 right-0 bottom-0 text-transparent"
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-5.svg`}
                />
              </span>
            </span>
            ADVANCED
          </TextRotateFromBottom>
          <TextRotateFromBottom
            index={4}
            onAnimationComplete={onAnimationComplete}
            classNames="p-[50px_40px_50px_0] m-[-50px_-40px_-50px_0]"
          >
            PFP
            <span className="relative ml-1">
              <span className="invisible tracking-[0.45em]">I</span>
              <span className="absolute top-[26%] h-[60%] w-full left-0">
                <Image
                  unoptimized
                  fill
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="h-[90%] w-auto left-[-16px] top-[52px] text-transparent"
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-6.webp`}
                />
              </span>
            </span>
            <span>
              :BYTES
              <span className="relative">
                <span className="invisible tracking-[1.1em]">I</span>
                <span className="absolute top-[21%] h-auto w-full left-[2px] aspect-[16/9]">
                  <Image
                    unoptimized
                    fill
                    alt=""
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="h-full w-full rounded-[5%] text-transparent"
                    src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-7.webp`}
                  />
                </span>
              </span>
            </span>
          </TextRotateFromBottom>
        </motion.h1>

        <motion.div
          initial="initial"
          animate={showDetail ? "animate" : "initial"}
          variants={{
            animate: { opacity: 1, transition: { duration: 0.5, delay: 0.5 } },
            initial: { opacity: 0 },
          }}
        >
          <p className="text-byteBlack-1000 text-[18px] leading-[24px] md:text-[24px] md:leading-[32px] max-w-full md:max-w-[843px] mt-[16px] mb-[20px] md:my-[32px]">
            bythen’s Advanced PFPs revolutionize digital identity with powerful
            3D AI avatars and a content creation platform, unlocking the value
            of your PFP IP.
          </p>

          <div className="flex flex-col-reverse md:flex-row space-y-3 mo:space-y-reverse md:space-y-0 space-x-0 md:space-x-[20px]">
            <Button type="white" classNames="" onClickButton={onOpenWhitepaper}>
              <span className="font-founders text-[16px] leading-[20px] md:text-[20px] md:leading-[20px] tracking-wide font-semibold mr-[8px]">
                READ WHITEPAPER
              </span>
              <div className="w-[24px] relative aspect-square flex justify-center items-center">
                <ArrowRightIcon color="black" />
              </div>
            </Button>

            <Button type="black" classNames="" onClickButton={onOpenDemo}>
              <span className="font-founders text-[16px] leading-[20px] md:text-[20px] md:leading-[20px] tracking-wide font-semibold mr-[8px]">
                SEE LIVE DEMO
              </span>
              <div className="w-[24px] aspect-square flex justify-center items-center">
                <Play width={14} height={14} weight="fill" />
              </div>
            </Button>
            {/* <button className="order-2 md:order-1 flex justify-between md:justify-center items-center border-1 border-byteBlack-1000 text-byteBlack-1000 rounded-none py-[14px] px-[16px] md:py-[16px] md:px-[24px]">
            </button>
            <button className="mb-[12px] md:mb-0 order-1 md:order-2 flex justify-between md:justify-center items-center bg-byteBlack-1000 text-white rounded-none py-[14px] px-[16px] md:py-[16px] md:px-[24px]">
              <span className="font-founders text-[16px] leading-[20px] md:text-[20px] md:leading-[20px] tracking-wide font-semibold mr-[8px]">
                SEE LIVE DEMO
              </span>
              <div className="w-[24px] aspect-square flex justify-center items-center">
                <Play width={14} height={14} weight="fill" />
              </div>
            </button> */}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
